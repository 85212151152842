//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VentaServices from "../../../Services/VentaServices";

const ventaServices = new VentaServices();
export default {
  name: "nuevoClienteModal",
  props: ["model"],
  data() {
    return {
      clienteAgregar: {
        categoriaIdCategoria: "",
        categoriaIdSubcategoria: "",
        folio: 1,
        tienda: "",
        clave: "",
        nombreComercial: "",
        telefono: "",
        comentario: "",
        email: "",
      },
      categorias: [],
      subcategorias: [],
    }
  },
  methods: {
    onClose() {this.$emit('on-close');},
    onShow() {
      this.loader(true);
      this.$nextTick(async () => {
        await this.obtenerCategorias();
        this.$refs.first.focus();
      });
      this.limpiarAlta();
      this.loader(false);
    },
    limpiarAlta() {
      this.clienteAgregar = {
        categoriaIdCategoria: "",
        categoriaIdSubcategoria: "",
        folio: 1,
        tienda: "",
        clave: "",
        nombreComercial: "",
        telefono: "",
        comentario: "",
        email: "",
      };
    },
    obtenerCategorias() {
      return ventaServices.obtenerCategorias()
        .then(cats => this.categorias = cats)
        .catch(error => this.alertShow(error, "error"));
    },
    obtenerSubcategorias() {
      this.loader(true);
      let data = {categoria: this.clienteAgregar.categoriaIdCategoria};
      return ventaServices.obtenerSubcategorias(data)
        .then(subcats => this.subcategorias = subcats)
        .catch(error => this.alertShow(error, "error"))
        .then(() => this.loader(false));
    },
    agregarCliente() {
      this.loader(true);
      return ventaServices.agregarCliente(this.clienteAgregar)
      .then(data => {
        this.alertShow("Se agregó el cliente correctamente.", "exito");
        this.$emit("get-cliente", data.data);
        this.onClose();
      }).catch(error => this.alertShow(error, "error"))
      .then(() => this.loader(false));
    }
  }
}
